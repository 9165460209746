html {
  background-color: rgba(255, 241, 234, 0.2);
  font-family: 'GT Eesti Text';
  height: 100%;
  width: 100%;
}

/* Remove the blue outline from all elements */
*:focus {
  outline: none !important; /* Completely removes the default focus outline */
  box-shadow: none !important; /* Removes any default focus shadow */
  -webkit-appearance: none; /* Removes default appearance on WebKit browsers */
}

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  Typographic tweaks!
  3. Add accessible line-height
  4. Improve text rendering
*/
body {
  height: 100%;
  width: 100%;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
  5. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  6. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  7. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
  8. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
  width: 100%;
  height: 100%;
}

/** magic to make autofill white */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

@font-face {
  font-family: 'GT Eesti Text';
  src: url('/fonts/GT-Eesti-Text-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Eesti Text';
  src: url('/fonts/GT-Eesti-Text-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Eesti Text';
  src: url('/fonts/GT-Eesti-Text-Book.woff2') format('woff2');
  font-weight: 450;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Thestral Neue';
  src: url('/fonts/ThestralNeue-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
